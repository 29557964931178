<template>
    <LayoutNext>
        <template #page-title>
            User's Revenue Report
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/users">Users</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    User's Revenue Report
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <MDBCard class="mb-4 shadow">
            <MDBCardHeader>
                <h5 class="fw-bold my-auto py-2">Monthly Revenue Report</h5>
            </MDBCardHeader>
            <MDBCardBody>
                <div class="d-flex mb-3">
                    <MDBSelect v-model:options="yearsOption" v-model:selected="MRYear" label="Year"
                        :setValue="MRYear" />
                </div>
                <ClientsMonthlyReportTableNext />
            </MDBCardBody>
        </MDBCard>

        <MDBCard class="shadow">
            <MDBCardHeader>
                <h5 class="fw-bold my-auto py-2">Monthly Asset Gross Revenue Report</h5>
            </MDBCardHeader>
            <MDBCardBody>
                <div class="d-flex mb-3 gap-3">
                    <MDBSelect v-model:options="monthsOption" v-model:selected="MRGrossMonth" label="Month"
                        :setValue="MRGrossMonth" />
                    <MDBSelect v-model:options="yearsOption2" v-model:selected="MRGrossYear" label="Year"
                        :setValue="MRGrossYear" />
                </div>
                <ClientsMonthlyAssetGrossReportTableNext />
            </MDBCardBody>
        </MDBCard>
        <DeductionsToast />
    </LayoutNext>
</template>

<script setup>
import LayoutNext from "@/views/v3/LayoutNext";
import { useTitle } from "@vueuse/core";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBSelect, MDBBreadcrumb, MDBBreadcrumbItem } from "mdb-vue-ui-kit";
import { onMounted, ref, watch } from "@vue/runtime-core";
import { useMonthlyReportStore } from "@/store/monthlyReport";
import { getCurrentMonth, getCurrentYear } from "@/helpers/parseDate";
import DeductionsToast from "@/components/Deductions/DeductionsToast.vue";
import { storeToRefs } from "pinia";
import ClientsMonthlyReportTableNext from "./ClientsMonthlyReportTableNext.vue";
import { useRoute } from "vue-router";
import ClientsMonthlyAssetGrossReportTableNext from "./ClientsMonthlyAssetGrossReportTableNext.vue";

useTitle("User's Monthly Report | CreatorShield");

const monthlyReportStore = useMonthlyReportStore();
const route = useRoute();
const { MRYear, MRGrossYear, MRGrossMonth, userId } =
    storeToRefs(monthlyReportStore);
const isDropdownSet = ref(false);

onMounted(() => {
    userId.value = route.params.id;
    MRGrossMonth.value = getCurrentMonth();
    MRYear.value = getCurrentYear();
    MRGrossYear.value = getCurrentYear();
    setDropdown();
    monthlyReportStore.getMonthlyReport();
    monthlyReportStore.getMonthlyGrossReport();
});

const setDropdown = () => {
    const newArray = monthsOption.value.map((item) => {
        if (item.value == MRGrossMonth.value) {
            return {
                ...item,
                selected: true,
            };
        } else {
            return { ...item, selected: false };
        }
    });

    monthsOption.value = newArray;

    const newYearArray = yearsOption.value.map((item) => {
        if (item.value == MRYear.value) {
            return {
                ...item,
                selected: true,
            };
        } else {
            return { ...item, selected: false };
        }
    });

    yearsOption.value = newYearArray;

    const newYearArray2 = yearsOption2.value.map((item) => {
        if (item.value == MRGrossYear.value) {
            return {
                ...item,
                selected: true,
            };
        } else {
            return { ...item, selected: false };
        }
    });
    yearsOption2.value = newYearArray2;
    isDropdownSet.value = true;
};

const monthsOption = ref([
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
]);

const yearsOption = ref([]);
const yearsOption2 = ref([]);
const createYearsOption = () => {
    for (let year = 2010; year <= 2100; year++) {
        yearsOption.value.push({
            text: year,
            value: year,
        });
    }
    for (let year = 2010; year <= 2100; year++) {
        yearsOption2.value.push({
            text: year,
            value: year,
        });
    }
};

createYearsOption();

watch(isDropdownSet, (newDropdown) => {
    if (newDropdown) {
        watch(
            () => MRYear.value,
            (newMRYear) => {
                MRYear.value = newMRYear;
                monthlyReportStore.getMonthlyReport();
            }
        );
        watch(
            () => MRGrossYear.value,
            (newMRGrossYear) => {
                MRGrossYear.value = newMRGrossYear;
                monthlyReportStore.getMonthlyGrossReport();
            }
        );
        watch(
            () => MRGrossMonth.value,
            (newMRGrossMonth) => {
                MRGrossMonth.value = newMRGrossMonth;
                monthlyReportStore.getMonthlyGrossReport();
            }
        );
    }
});
</script>